import React, {useEffect} from 'react';
import UtilBranLock from '@economist/fabric-components/utility/utilbranlock/UtilBranLock';
import {withPrefix} from 'gatsby';
import {motion, useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import classes from './CardSection.module.scss';
import LinkButtonCircular from '../../UI/ButtonCircular/LinkButtonCircular';
import config from '../../../config/config';
import Comment from '../../Comment/Comment';
import {useWindowWidth} from '@react-hook/window-size/throttled';
import PropTypes from 'prop-types';

const CardSection = ({
     position = 'left',
     authorImg,
     data: {
         bgImg,
         main,
         additional,
         more
     },

 }) => {
    const sectionAnim = useAnimation();
    const bgImgAnim = useAnimation();
    const additionalImgAnim = useAnimation();
    const mainAnim = useAnimation();

    const sectionClassList = [
        classes.section,
        position === 'left' ? classes.sectionLeft : classes.sectionRight
    ].join(' ');

    const windowWidth = useWindowWidth();

    const [ref, inView] = useInView({
        threshold: .1,
        triggerOnce: true,
        rootMargin: '320px'
    });

    useEffect(() => {
        if (inView) {
            sectionAnim.start('visible');
            bgImgAnim.start('visible');
            additionalImgAnim.start('visible');
            mainAnim.start('visible');
        }
    }, [windowWidth, inView]);

    return (
        <>
            <div ref={ref}>
                <motion.div
                    animate={sectionAnim}
                    initial="hidden"
                    variants={{
                        visible: {opacity: 1, y: 0},
                        hidden: {opacity: 0, y: '500px'}
                    }}
                    transition={{type: 'spring', stiffness: 40}}
                    className={sectionClassList}
                >
                    <div className={classes.wrapper}>
                        <motion.div
                            animate={bgImgAnim}
                            initial="hidden"
                            variants={{
                                visible: {opacity: 1, y: 0},
                                hidden: {opacity: 0, y: '500px'}
                            }}
                            transition={{type: 'spring', stiffness: 40, delay: .2}}
                            className={classes.wrapperBgImg}>
                            <img srcSet={withPrefix(bgImg)} alt=""/>
                        </motion.div>

                        <div className={classes.content}>
                            <div className={classes.additionalCol}>

                                <motion.div
                                    animate={additionalImgAnim}
                                    initial="hidden"
                                    variants={{
                                        visible: {opacity: 1, y: 0},
                                        hidden: {opacity: .5, y: '600px'}
                                    }}
                                    transition={{type: 'spring', stiffness: 40, delay: .5}}
                                >
                                    <div className={classes.additionalColImg}
                                         style={{ backgroundImage: `url('${withPrefix(additional.img)}')` }} />
                                </motion.div>

                                {
                                    windowWidth > config.mobileWidth && !!additional.comment
                                        ? <Comment
                                            isVisible={inView}
                                            addClass={classes.comment}
                                            comment={additional.comment} />
                                        : null
                                }
                            </div>
                            <motion.div
                                animate={mainAnim}
                                initial="hidden"
                                variants={{
                                    visible: {opacity: 1, x: 0},
                                    hidden: {opacity: 0, x: position === 'left' ? '500px' : '-500px'}
                                }}
                                transition={{type: 'spring', stiffness: 40, delay: .7}}
                                className={classes.mainCol}
                            >
                                <UtilBranLock alt="written by logo" text={main.by || 'written by'} align="flex-end"
                                              logo={withPrefix(authorImg || '/images/svg/EIUSVG.svg')}/>

                                <h4 className={classes.cardSectionTitle}>{main.title}</h4>

                                <div className={classes.cardSectionContent} dangerouslySetInnerHTML={{__html: main.content}} />

                                {
                                    windowWidth <= config.mobileWidth && !!additional.comment
                                        ? <Comment
                                            isVisible={inView}
                                            addClass={classes.comment}
                                            comment={additional.comment} />
                                        : null
                                }

                                {
                                    more
                                        ? <LinkButtonCircular to={more.link} icon={true}>{more.label}</LinkButtonCircular>
                                        : null
                                }
                            </motion.div>
                        </div>
                    </div>

                </motion.div>
            </div>
        </>
    )
};

CardSection.propTypes = {
    data: PropTypes.shape({
        main: PropTypes.object.isRequired,
        additional: PropTypes.object.isRequired,
        bgImg: PropTypes.string,
        more: PropTypes.object
    }).isRequired,
    position: PropTypes.oneOf(['left', 'right']),
    authorImg: PropTypes.string
};

export default CardSection;
