import React from 'react';
import classes from './InfographicColumnSection.module.scss';
import PropTypes from 'prop-types';
import InfographicColumn from "./components/InfographicColumn/InfographicColumn";
import {withPrefix} from "gatsby";

const InfographicColumnSection = ({preHeaderText, columns}) => {
  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.infoPanel}>
          <div className={classes.contentContainer}>
            <div dangerouslySetInnerHTML={{__html: preHeaderText}} />
          </div>
        </div>
          <div className={classes.columns}>
            {columns.map((column, idx) => <InfographicColumn column={column} key={idx}/>)}
          </div>
          <div className={classes.description}>
            <p>
              *Refers to a drop in the first half of 2020
            </p>
          </div>

        <div className={classes.mobile}>
          <div className={classes.imgContainer}>
            <img src={withPrefix('images/infographicMobile/group_with_text_01.png')} alt="" />
          </div>
        </div>
      </div>
    </>
  )
};

InfographicColumnSection.propTypes = {
  preHeaderText: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired
};

export default InfographicColumnSection;
