import React from 'react';
import classes from './InfographicContentType8.module.scss';
import {withPrefix} from "gatsby";
import HeadAdv from "@economist/fabric-components/header/headadv/HeadAdv";

const InfographicContentType8 = () => {
  return (
    <>
      <div className={classes.contentContainer}>
        <div className={classes.firstRowContent}>
          <div className={classes.leftContainer}>
            <div className={classes.textContent}>
              <p className={classes.textStyle}>
                The smart cities market value
              </p>
              <p>
                (Markets and Markets, 2020)
              </p>
            </div>
            <img src={withPrefix('images/infographicDesktop/Group345.png')} alt=""/>
          </div>
          <div className={classes.rightContainer}>
            <p>
              The 2020 Smart City Index placed Helsinki and Zurich at the top alongside Singapore, but also saw a
              number of European cities fall down the rankings as coronavirus redirected resources away from smart city
              planning and towards coping with the pandemic. Countries in the Middle East have ambitious plans, with
              at least 10–15 large proposals on entire smart cities set-ups across all functionalities.
            </p>
          </div>
        </div>
        <div>
          <div className={classes.text}>
            <p>
              <span className={classes.textContentStyle}>Financing for smart cities is currently a combination of:</span>
              <br />
              (Deloitte, 2021)
            </p>
          </div>
          <img src={withPrefix('images/infographicMobile/Group_376.png')} alt="" className={classes.graph}/>
          <div className={classes.lastGraphContainer}>
            <div>
              <img src={withPrefix('images/infographicDesktop/Group340.png')} alt=""/>
              <p>Public and private funding</p>
            </div>
            <div>
              <img src={withPrefix('images/infographicDesktop/Group341.png')} alt=""/>
              <p>Mix of public funds</p>
            </div>
            <div>
              <img src={withPrefix('images/infographicDesktop/Group342.png')} alt=""/>
              <p>State grants</p>
            </div>
            <div>
              <img src={withPrefix('images/infographicDesktop/Group343.png')} alt=""/>
              <p>Regional grants</p>
            </div>
            <div>
              <img src={withPrefix('images/infographicDesktop/Group344.png')} alt=""/>
              <p>Private financing</p>
            </div>
          </div>
        </div>
        <div>
          <p className={classes.lastContent}>
            With strained government budgets, funding for smart cities in a post-Covid recovery will continue to be
            a challenge, but broad-based programmes such as the EU’s Green New Deal will provide important incentives.
          </p>
        </div>
      </div>
      <div className={classes.brand}>
        <HeadAdv
          textColor="#000"
          sponsorLogo={withPrefix('images/innovation-is-great.png')}
        />
      </div>
    </>
  )
};

export default InfographicContentType8;
