export default {
  pageTitle: 'Infographic | Department for International Trade',
  pageDescription: `As the coronavirus pandemic affected economies worldwide, global Foreign Direct Investment (FDI) and global trade flows fell sharply.`,
  infographicsHeroSection: {
    title: 'Trade and investment challenges and opportunities in the post-pandemic world',
    background: 'images/background.png'
  },
  infographicsInfoColumnSection: {
    preHeaderText: `<p>As the coronavirus pandemic affected economies worldwide, global Foreign Direct Investment 
       (FDI) and global trade flows fell sharply. <br />Compared to the same period in 2019:</p>`,
    columns: [
      {
        text: 'FDI flows',
        percent: 42
      }, {
        text: 'Cross-border mergers and acquisitions (M&A)',
        percent: 10
      }, {
        text: 'New greenfield investment project announcements',
        percent: 35
      }, {
        text: 'Newly announced cross-border project finance deals in the first six ' +
          'months of the year before a sharp uptick in the second half (UNCTAD, 2021)',
        percent: 25
      }
    ]
  },
  infographicsRaceStatisticsSection: {
    lightSubText: '2020',
    darkSubText: '2021',
    light: [
      {
        title: `<p><span>Global trade in goods</span> was expected to fall by</p>`,
        percentImgPath: 'images/infographicMobile/Group360.png',
        text: `<p>the biggest fall since the 2009 banking crisis</p>`
      }, {
        title: `<p><span>Global trade in services</span> was estimated to decline by</p>`,
        percentImgPath: 'images/infographicMobile/Group361.png',
        text: `<p>the steepest drop in 30 years <br />(UNCTAD, 2020)</p>`
      }
    ],
    dark: [
      {
        title: `<p>The trade volumes are expected to recover by just under</p>`,
        percentImgPath: 'images/infographicMobile/Group377.png',
        text: `<p>falling short of the total decline of around 10% expected in 2020 (EIU, 2020)</p>`
      }
    ]
  },
  infographicsCardSectionOneContent: {
    aboveSection: [
      {
        firstContent: `
        <p>As global dependence on digital technologies increases - and as lockdowns force individuals, businesses
        and governments to move their operations online - the risks of cyberthreats will continue to rise.</p>
        <p>
        It is estimated that the overall global cost of cybercrime might reach
        </p>
        `,
        img: 'images/infographicDesktop/Group_362.png',
        secondContent: `<p>
          while the average cost of breaches disclosed by public firms hit $116m in 2019, a figure likely to
          have been exceeded in 2020
          <br />
          (Cybersecurity Ventures, 2020; Audit Analytics, 2020).
        </p>`
      }
    ],
    belowSection: [
      {
        title: `<p>The cybersecurity market is expected to grow</p>`,
        img: 'images/infographicDesktop/124bn_170_4bn_02.png',

      },
      {
        firstContent: `<p>The US currently leads the market, but other markets are catching up, especially the UK. Cyber was the
              UK’s fastest growing start-up market during the pandemic, with almost</p>`,
        img: 'images/infographicDesktop/Group364.png',
        secondContent: `<p>raised in the first half of the year alone
              <br />
              (Private Equity Wire, 2020).</p>`
      },
      {
        firstContent: `<p>
                That said, there is still room for improvement. While just 4% of companies surveyed by McAfee claimed
                not to have experienced any sort of cyber incident in 2019, more than half of those organisations said
                they had no plans to both prevent and respond to cyber incidents (McAfee, 2020).
              </p>`,
      },
      {
        firstContent: `<p>
                  As cyberthreats become more widespread, opportunities to invest in the necessary skills and
                  technologies will follow, as businesses and governments look to improve the safety and security
                  of their data.</p>`,
        img: 'images/infographicDesktop/Group316.png',
      }
    ]
  },
  infographicsCardSectionTwoContent: {
    aboveSection: [
      {
        firstContent: `
            <p>
              FinTech has gone from occupying a niche space in the tech industry, to making its way across a variety of
              sub-sectors, from insurance, payments and regulation technology (‘regtech’) to banking and lending, wealth
              tech and financial infrastructure.
            </p>`,
        img: 'images/infographicDesktop/Group_351.png',
        secondContent: `
            <p>
              <span className={classes.boldText}>The global FinTech market estimated value</span>
              <br />
              (The Business Research Company, 2020)
            </p>`
      }
    ],
    belowSection: [
      {
        firstContent:
          `<p>
              The coronavirus pandemic has sped up demand for e-commerce and
              payment platforms, accelerating the use of FinTech. For instance, the UK, saw about 6 million adults
              download an online banking app for the first time in 2020
           </p>`,
        img: 'images/infographicDesktop/Group368.png',

      },
      {
        firstContent:
          `<p>
              of over 65s using banking apps during the pandemic (Nucoro, 2020; Mastercard 2020).
          </p>`,
        img: 'images/infographicDesktop/Group369.png',
      },
      {
        firstContent:
          `<p>
              In that sense, while earlier stage FinTech ventures have faced a slowdown in financing from venture
              capital, research suggests that just 3% of FinTechs have been critically affected by the pandemic
              to date, pointing to the sector’s relatively good position with regards to the economic effects of
              the pandemic.
          </p>`,
      },
      {
        firstContent:
          `<p>
               Financing in the sector remained largely resilient in 2020. Global FinTech investments reached
          </p>`,
        img: 'images/infographicDesktop/Group316.png',
        secondContent:
          `<p>
               across more than 3,000 worldwide deals over the year.
          </p>`,
        thirdContent:
          `<p>
               The table was led by the US, accounting for $22bn, followed by the UK, with $4.1bn invested in over 400 deals (Innovate Finance, 2021).
          </p>`
      }
    ]
  },
  infographicsCardSectionThreeContent: {
    aboveSection: [
      {
        firstContent: `
            <p>
                EdTech has long been used by schools, employers and individuals, but worldwide lockdowns have been a catalyst
                for the rapid adoption of remote and digital education solutions, with the EdTech market set to be worth
            </p>`,
        img: 'images/infographicDesktop/Group350.png',
      }
    ],
    belowSection: [
      {
        firstContent:
          `<p>
                in
          </p>`,
        img1: 'images/infographicDesktop/Group348_picture.png',
        img2: 'images/infographicDesktop/Group349_picture.png',
        secondContent:
          `<p className={classes.textContent}>
              have been affected by partial or full school closures
          </p>`

      },
      {
        firstContent:
          `<p className={classes.textContent}>
              of the world’s school and university students had used some form of digital tools to continue learning
              from home during the pandemic
              <br />
              (UNESCO, 2020; Credit Suisse, 2020).
          </p>`,
        img: 'images/infographicDesktop/Group324.png',
      },
      {
        firstContent:
          `The uptake of EdTech also features in the business sector and at the government level`,
        img: 'images/infographicDesktop/Group325.png',
        secondContent: 'Businesses looking to digitise their organisation',
        thirdContent: 'Governments looking to reskill and upskill people for the increased demand in digital jobs'
      },
      {
        firstContent: `However, the sudden and rapid increase in EdTech has also evinced 
              the issue of social inequality, with digital divides over access to technology becoming more apparent.`,
        img: 'images/infographicDesktop/Group316.png',
        secondContent:
          'In the UK, pupils provided with devices to take home',
        firstTitle:
          'primary state schools',
        secondTitle:
          'private primary schools'
      }
    ]
  },
  infographicsCardSectionFourContent: {
    aboveSection: [
      {
        firstContent: `
            The pandemic has the potential to kick-start a more systematic approach to smart cities, as it showed the
        need for coordinated and more sophisticated urban services, not least in healthcare, housing and transport,
        and highlighted the importance of green recoveries.
        `,
        img: 'images/infographicDesktop/Group272.png',
      }
    ],
    belowSection: [
      {
        firstTitle:
          `<p className={classes.textStyle}>
            The smart cities market value
          </p>
          <p>
            (Markets and Markets, 2020)
          </p>`,
        firstContent:
          `<p>
              The 2020 Smart City Index placed Helsinki and Zurich at the top alongside Singapore, but also saw a
              number of European cities fall down the rankings as coronavirus redirected resources away from smart city
              planning and towards coping with the pandemic. Countries in the Middle East have ambitious plans, with
              at least 10–15 large proposals on entire smart cities set-ups across all functionalities.
          </p>`,
        img1: 'images/infographicDesktop/Group345.png',
      },
      {
        firstContent:
          `<p>
              <span className={classes.textContentStyle}>Financing for smart cities is currently a combination of:</span>
              <br />
              (Deloitte, 2021)
        </p>`,
        alternImg: 'images/infographicMobile/Group_376.png',
        img1: 'images/infographicDesktop/Group340.png',
        title1: 'Public and private funding',
        img2: 'images/infographicDesktop/Group341.png',
        title2: 'Mix of public funds',
        img3: 'images/infographicDesktop/Group342.png',
        title3: 'State grants',
        img4: 'images/infographicDesktop/Group343.png',
        title4: 'Regional grants',
        img5: 'images/infographicDesktop/Group344.png',
        title5: 'Private financing',
      },
      {
        firstContent:
          `With strained government budgets, funding for smart cities in a post-Covid recovery will continue to be
            a challenge, but broad-based programmes such as the EU’s Green New Deal will provide important incentives.`,
      }
    ]
  },
  infographicsCardSectionOne: {
    frontImgPath: 'images/infographicDesktop/Group322.png',
    headerComponent: 'ContentType1',
    bodyComponent: 'ContentType2'
  },
  infographicsCardSectionTwo: {
    frontImgPath: 'images/infographicDesktop/Group321.png',
    headerComponent: 'ContentType3',
    bodyComponent: 'ContentType4'
  },
  infographicsCardSectionThree: {
    frontImgPath: 'images/infographicDesktop/Group320.png',
    headerComponent: 'ContentType5',
    bodyComponent: 'ContentType6'
  },
  infographicsCardSectionFour: {
    frontImgPath: 'images/infographicDesktop/Group323.png',
    headerComponent: 'ContentType7',
    bodyComponent: 'ContentType8'
  },
  cardSectionOne: {
    position: 'left',
    bgImg: 'images/svg/square-gradient.svg',
    main: {
      title: 'Digitalisation, datafication and the green agenda will continue to shape businesses and industries.',
      content: `<p>The disruption of the trade and investment landscape will have a lasting impact, with some sectors 
			hit particularly hard. Some will take longer to bounce back, and may look quite different once they do so. But 
			in many cases, the future will be shaped by the deepening and acceleration of several existing trends, particularly 
			digitalisation, datafication and the green agenda.</p>`
    },
    additional: {
      img: 'images/homepage_chart.jpg',
    },
    more: {
      label: 'Read more',
      link: '/briefing-paper/'
    }
  },
}
