import React from 'react';
import classes from './InfographicContentType4.module.scss';
import {withPrefix} from "gatsby";

const InfographicContentType4 = () => {
  const rowStyle = (rowNum) => {
    return [
      classes.rowContainer,
      classes[`${rowNum}RowContainer`]
    ].join(' ');
  }

  const aboveCellContainer = (cellContNum) => {
    return [
      classes.aboveCellContainer,
      classes[`${cellContNum}AboveCellContainer`]
    ].join(' ');
  }

  const belowCellContainer = (cellContNum) => {
    return [
      classes.belowCellContainer,
      classes[`${cellContNum}BelowCellContainer`]
    ].join(' ');
  }

  return (
    <>
      <div className={classes.wrapper}>
        <div className={rowStyle("first")}>
          <div className={aboveCellContainer("first")}>
            <div className={classes.leftBlock}>
              <img src={withPrefix('images/infographicDesktop/Group368.png')} alt=""/>
            </div>
            <div className={classes.rightBlock}>
              <p>The coronavirus pandemic has sped up demand for e-commerce and
              payment platforms, accelerating the use of FinTech. For instance, the UK, saw about 6 million adults
              download an online banking app for the first time in 2020</p>
            </div>
          </div>
          <div className={aboveCellContainer("second")}>
            <div className={classes.leftBlock}>
              <img src={withPrefix('images/infographicDesktop/Group369.png')} alt="" />
            </div>
            <div className={classes.rightBlock}>
              <p>of over 65s using banking apps during the pandemic (Nucoro, 2020; Mastercard 2020).</p>
            </div>
          </div>
        </div>
        <div className={rowStyle("second")}>
          <div className={belowCellContainer("third")}>
            <div className={classes.belowRowContainer}>
              <p>
                In that sense, while earlier stage FinTech ventures have faced a slowdown in financing from venture
                capital, research suggests that just 3% of FinTechs have been critically affected by the pandemic
                to date, pointing to the sector’s relatively good position with regards to the economic effects of
                the pandemic.
              </p>
            </div>
          </div>
          <div className={belowCellContainer("fourth")}>
            <div className={classes.belowRowContainer}>
              <p>
                Financing in the sector remained largely resilient in 2020. Global FinTech investments reached
              </p>
              <div>
                <div className={classes.contentContainer}>
                  <div>
                    <img src={withPrefix('images/infographicDesktop/44bn.png')} alt="" />
                  </div>
                  <div className={classes.text}>
                    <p>across more than 3,000 worldwide deals over the year.</p>
                  </div>
                </div>

              </div>
              <p>
                The table was led by the US, accounting for $22bn, followed by the UK, with $4.1bn invested in over 400 deals (Innovate Finance, 2021).
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default InfographicContentType4;
