import React from 'react';
import classes from './InfographicContentType5.module.scss';
import {withPrefix} from "gatsby";


const InfographicContentType5 = () => {

  return (
    <>
    <div className={classes.contentWrapper}>
      <div className={classes.contentContainer}>
        <p>
          EdTech has long been used by schools, employers and individuals, but worldwide lockdowns have been a catalyst
          for the rapid adoption of remote and digital education solutions, with the EdTech market set to be worth
        </p>
      </div>

      <div>
        <img src={withPrefix('images/infographicDesktop/Group350.png')} alt="" />
      </div>
    </div>
    </>
  )
};

export default InfographicContentType5;
