import React from 'react';
import classes from './InfographicContentType3.module.scss';
import {withPrefix} from "gatsby";


const InfographicContentType3 = () => {

  return (
    <>
      <div className={classes.contentContainer}>
        <div>
          <div className={classes.firstParagraph}>
            <p>
              FinTech has gone from occupying a niche space in the tech industry, to making its way across a variety of
              sub-sectors, from insurance, payments and regulation technology (‘regtech’) to banking and lending, wealth
              tech and financial infrastructure.
            </p>
          </div>
          <div className={classes.secondParagraph}>
            <p>
              <span className={classes.boldText}>The global FinTech market estimated value</span>
              <br />
              (The Business Research Company, 2020)
            </p>
          </div>
          <div className={classes.pictContainer}>
            <img src={withPrefix('images/infographicDesktop/Group_351.png')} alt=""/>
          </div>
        </div>
      </div>
    </>
  )
};

export default InfographicContentType3;
