import React from 'react';
import classes from './InfographicContentType7.module.scss';
import {withPrefix} from "gatsby";


const InfographicContentType7 = () => {

  return (
    <>
    <div>
      <p className={classes.contentContainer}>
        The pandemic has the potential to kick-start a more systematic approach to smart cities, as it showed the
        need for coordinated and more sophisticated urban services, not least in healthcare, housing and transport,
        and highlighted the importance of green recoveries.
      </p>
      <div className={classes.pictureContainer}>
        <img src={withPrefix('images/infographicDesktop/Group272.png')} alt=""/>
      </div>
    </div>
    </>
  )
};

export default InfographicContentType7;
