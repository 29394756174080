import React from 'react';
import classes from './InfographicContentType2.module.scss';
import {withPrefix} from "gatsby";

const InfographicContentType2 = () => {
    const rowStyle = (rowNum) => {
      return [
        classes.rowContainer,
        classes[`${rowNum}RowContainer`]
      ].join(' ');
    }

    const aboveCellContainer = (cellContNum) => {
      return [
        classes.aboveCellContainer,
        classes[`${cellContNum}AboveCellContainer`]
      ].join(' ');
    }

    const belowCellContainer = (cellContNum) => {
      return [
        classes.belowCellContainer,
        classes[`${cellContNum}BelowCellContainer`]
      ].join(' ');
    }

  return (
    <>
      <div className={classes.wrapper}>
        <div className={rowStyle("first")}>
          <div className={aboveCellContainer("first")}>
              <div className={classes.textContent}>
                <p>The cybersecurity market is expected to grow</p>
              </div>
              <img src={withPrefix('images/infographicDesktop/124bn_170_4bn_02.png')} alt="" />
          </div>

          <div className={aboveCellContainer("second")}>
            <p>The US currently leads the market, but other markets are catching up, especially the UK. Cyber was the
              UK’s fastest growing start-up market during the pandemic, with almost</p>
            <div className={classes.textContainer}>
              <img src={withPrefix('images/infographicDesktop/Group364.png')} alt="" />
            </div>
            <p>raised in the first half of the year alone
              <br />
              (Private Equity Wire, 2020).</p>
          </div>
        </div>
        <div className={rowStyle("second")}>
          <div className={belowCellContainer("third")}>
            <div className={classes.belowRowContainer}>
              <p>
                That said, there is still room for improvement. While just 4% of companies surveyed by McAfee claimed
                not to have experienced any sort of cyber incident in 2019, more than half of those organisations said
                they had no plans to both prevent and respond to cyber incidents (McAfee, 2020).
              </p>
            </div>
          </div>
          <div className={belowCellContainer("fourth")}>
            <div className={classes.belowRowContainer}>
              <img src={withPrefix('images/infographicDesktop/Group316.png')} alt="" className={classes.picBelowRowContainer}/>
              <div className={classes.rightBlock}>
                <p>
                  As cyberthreats become more widespread, opportunities to invest in the necessary skills and
                  technologies will follow, as businesses and governments look to improve the safety and security
                  of their data.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default InfographicContentType2;
