import React from "react";
import Layout from '../../components/Layout/Layout';
import Seo from "../../components/Seo/Seo";

/*  Data imports    */
import pageData from '../../constants/pages/infographic';

import ScrollDepth from '@economist/fabric-components/utility/ScrollDepthTracker/ScrollDepth';
import UtilWebVitals from '@economist/fabric-components/utility/utilwebvitals/UtilWebVitals';
import {Page} from '../../components/Page';
import InfographicHeroSection from "../../components/Sections/InfographicHeroSection/InfographicHeroSection";
import InfographicColumnSection from "../../components/Sections/InfographicColumnSection/InfographicColumnSection";
import InfographicRaceStatistic
  from "../../components/Sections/InfographicRaceStatisticSection/InfographicRaceStatistic";
import InfographicCardSection from "../../components/Sections/InfographicCardSection/InfographicCardSection";
import {Helmet} from "react-helmet";
import CardSection from "../../components/Sections/CardSection/CardSection";
import {useWindowWidth} from "@react-hook/window-size/throttled";
import config from "../../config/config";
import InfographicMobileHeroSection
  from "../../components/Sections/InfographicMobileHeroSection/InfographicMobileHeroSection";
import {withPrefix} from "gatsby";
import HeadAdv from "@economist/fabric-components/header/headadv/HeadAdv";
import CardSectionSec from "../../components/Sections/CardSectionSec/CardSectionSec";
import UtilBranLock from "@economist/fabric-components/utility/utilbranlock/UtilBranLock";

const InfographicsPage = () => {
  const windowWidth = useWindowWidth();

  const infographicsHero = pageData.infographicsHeroSection ?
    <InfographicHeroSection
      title={pageData.infographicsHeroSection.title}
      background={pageData.infographicsHeroSection.background}
    />
    : null;

  const infographicsMobileHeroSection = pageData.infographicsHeroSection ?
    <InfographicMobileHeroSection
      title={pageData.infographicsHeroSection.title}
    />
    : null;

  const infographicColumn = pageData.infographicsInfoColumnSection ?
    <InfographicColumnSection
      preHeaderText={pageData.infographicsInfoColumnSection.preHeaderText}
      columns={pageData.infographicsInfoColumnSection.columns}
    />
    : null;

  const infographicsRaceStatistics = pageData.infographicsRaceStatisticsSection ?
    <InfographicRaceStatistic
      lightSubText={pageData.infographicsRaceStatisticsSection.lightSubText}
      darkSubText={pageData.infographicsRaceStatisticsSection.darkSubText}
      light={pageData.infographicsRaceStatisticsSection.light}
      dark={pageData.infographicsRaceStatisticsSection.dark}
    />
    : null;

  const infographicsCardSectionOne = pageData.infographicsCardSectionOne ?
    <InfographicCardSection
      frontImgPath={pageData.infographicsCardSectionOne.frontImgPath}
      backgroundGradientImgPath={pageData.infographicsCardSectionOne.backgroundGradientImgPath}
      headerComponent={pageData.infographicsCardSectionOne.headerComponent}
      bodyComponent={pageData.infographicsCardSectionOne.bodyComponent}/>
    : null;

  const infographicsCardSectionTwo = pageData.infographicsCardSectionTwo ?
    <InfographicCardSection
      frontImgPath={pageData.infographicsCardSectionTwo.frontImgPath}
      backgroundGradientImgPath={pageData.infographicsCardSectionTwo.backgroundGradientImgPath}
      headerComponent={pageData.infographicsCardSectionTwo.headerComponent}
      bodyComponent={pageData.infographicsCardSectionTwo.bodyComponent}/>
    : null;

  const infographicsCardSectionThree = pageData.infographicsCardSectionThree ?
    <InfographicCardSection
      frontImgPath={pageData.infographicsCardSectionThree.frontImgPath}
      backgroundGradientImgPath={pageData.infographicsCardSectionThree.backgroundGradientImgPath}
      headerComponent={pageData.infographicsCardSectionThree.headerComponent}
      bodyComponent={pageData.infographicsCardSectionThree.bodyComponent}/>
    : null;

  const infographicsCardSectionFour = pageData.infographicsCardSectionFour ?
    <InfographicCardSection
      frontImgPath={pageData.infographicsCardSectionFour.frontImgPath}
      backgroundGradientImgPath={pageData.infographicsCardSectionFour.backgroundGradientImgPath}
      headerComponent={pageData.infographicsCardSectionFour.headerComponent}
      bodyComponent={pageData.infographicsCardSectionFour.bodyComponent}/>
    : null;

  const cardSectionOne = pageData.cardSectionOne
    ?
    <CardSectionSec
      position={pageData.cardSectionOne.position || 'left'}
      data={pageData.cardSectionOne}
      paddingTop={'60px'}
      background={'#fff'}
      indentBottom={false}
      smallTextOnMobile={true}
      offAnimations={true}
      smallBg={true}
      authorComponent={
        <UtilBranLock
          alt="written by"
          text="written by"
          alignMobile="center"
          logo={withPrefix('/images/svg/EIUSVG.svg')
          }/>
      }
    />
    : null;

  const pageStyles = [{
    cssText: `
			body {
				background-color: var(--gray-E4EFF0);
			}
		`
  }];

  return (
    <>
    <Seo title={pageData.pageTitle} description={pageData.pageDescription}/>
    <Page>
      <Layout menuChild={windowWidth > config.mobileWidth ? infographicsHero : null} invertMenu={true}>
        
        <UtilWebVitals/>
        <Helmet style={pageStyles}/>
        <ScrollDepth/>

        <div style={{maxWidth: "1440px", margin: "0 auto"}}>

          {windowWidth <= config.mobileWidth ? infographicsMobileHeroSection : null}

          {infographicColumn}

          {infographicsRaceStatistics}

          {infographicsCardSectionOne}

          {infographicsCardSectionTwo}

          {infographicsCardSectionThree}

          {infographicsCardSectionFour}

          {cardSectionOne}

        </div>

      </Layout>
    </Page>
    </>
  )
};

export default InfographicsPage
