import React from 'react';
import classes from './InfographicRaceStatistic.module.scss';
import PropTypes from 'prop-types';
import StatisticBoxLight from "./components/InfographicRaceStatistic/StatisticBoxLight";
import StatisticBoxDark from "./components/InfographicRaceStatistic/StatisticBoxDark";

const InfographicRaceStatistic = ({light, lightSubText, dark, darkSubText}) => {
  let amount = light.length + dark.length;

  const cleaner = () => {
    if (amount > 4) {
      dark.length >= light.length
        ? dark.splice(dark.length - 2, dark.length)
        : light.splice(light.length - 2, light.length);

      amount = light.length + dark.length;
      cleaner();
    }
  }
  cleaner();

  const percentWidth = Math.ceil(100 / amount);

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.boxes}>
          {light.length ? <div className={classes.contentContainer} style={{width: `${percentWidth * light.length}%`}}>
            <div className={classes.absolute}>
              <div className={classes.date}><span>{lightSubText}</span></div>
            </div>

            {light.map((light, idx) => <StatisticBoxLight light={light} key={idx}/>)}
          </div> : null}

          {dark.length ? <div className={classes.contentContainer} style={{width: `${percentWidth * dark.length}%`}}>
            <div className={classes.absolute}>
              <div className={classes.date}><span>{darkSubText}</span></div>
            </div>

            {dark.map((dark, idx) => <StatisticBoxDark dark={dark} key={idx}/>)}
          </div> : null}
        </div>
        <div className={classes.contentTextContainer}>
          <p>
            The pandemic is also accelerating some underlying trends, such as digitalisation, datafication and the
            green agenda, which will fundamentally shape the recovery and future prospects of many businesses and sectors.
          </p>
          <p>
            Businesses and sub-sectors that can participate in these trends, such as Cybersecurity, FinTech, EdTech
            and Smart Cities, will see new opportunities for growth and investment.
          </p>
        </div>
      </div>

    </>
  )
};

InfographicRaceStatistic.propTypes = {
  light: PropTypes.array.isRequired,
  dark: PropTypes.array.isRequired,
  darkSubText: PropTypes.string.isRequired,
  lightSubText: PropTypes.string.isRequired
};

export default InfographicRaceStatistic;
