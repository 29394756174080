import React from 'react';
import PropTypes from 'prop-types';
import {withPrefix} from 'gatsby';
import classes from './InfographicMobileHeroSection.module.scss';
import UtilBranLock from "@economist/fabric-components/utility/utilbranlock/UtilBranLock";

const InfographicMobileHeroSection = ({title}) => {

	return (
		<>
			<div className={classes.content}>
				<div className={classes.brand}>
					<UtilBranLock alt="written by logo" text={'An infographic by'}
												align="center"
												logo={withPrefix('/images/svg/EIUSVG.svg')}/>
				</div>
					<h2>{title}</h2>
			</div>
		</>
	)
};

InfographicMobileHeroSection.propTypes = {
	title: PropTypes.string
};

export default InfographicMobileHeroSection;
