import React, {useEffect} from 'react';
import {withPrefix} from 'gatsby';
import classes from './InfographicHeroSection.module.scss';
import PropTypes from 'prop-types';
import UtilBranLock from "@economist/fabric-components/utility/utilbranlock/UtilBranLock";
import {motion} from "framer-motion";

const InfographicHeroSection = ({title, background}) => {
	return (
		<>
			<div className={classes.wrapper} style={{backgroundImage: `url('${withPrefix(background)}')`}}>

					<div className={classes.content}>
						<h2>{title}</h2>

						<div className={classes.brand}>
							<UtilBranLock alt="written by logo" text={'An infographic by'}
														align="center"
														logo={withPrefix('/images/svg/EIUSVG.svg')}/>
						</div>
					</div>

			</div>
		</>
	)
};

InfographicHeroSection.propTypes = {
	title: PropTypes.string.isRequired,
	background: PropTypes.string.isRequired
};

export default InfographicHeroSection;
