import React from 'react';
import classes from './InfographicColumn.module.scss';
import PropTypes from 'prop-types';
import {withPrefix} from "gatsby";

const InfographicColumn = ({column: {text, percent}}, type) => {
  return (
    <>
      <div className={classes.column} style={{height: `${percent * 15}px`}}>
        <span className={classes.text}>{text}</span>

        <span className={classes.percent}>{`-${percent}%`}</span>

        <img src={withPrefix('images/triangle.png')} alt="icon"/>
      </div>
    </>
  )
};

InfographicColumn.propTypes = {
  column: PropTypes.shape({
    text: PropTypes.string.isRequired,
    percent: PropTypes.number.isRequired
  }).isRequired
};

export default InfographicColumn;
