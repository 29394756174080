import React from 'react';
import classes from './InfographicContentType6.module.scss';
import {withPrefix} from "gatsby";

const InfographicContentType6 = () => {
    const rowStyle = (rowNum) => {
      return [
        classes.rowContainer,
        classes[`${rowNum}RowContainer`]
      ].join(' ');
    }

    const aboveCellContainer = (cellContNum) => {
      return [
        classes.aboveCellContainer,
        classes[`${cellContNum}AboveCellContainer`]
      ].join(' ');
    }

    const belowCellContainer = (cellContNum) => {
      return [
        classes.belowCellContainer,
        classes[`${cellContNum}BelowCellContainer`]
      ].join(' ');
    }

  return (
    <>
      <div className={classes.wrapper}>
        <div className={rowStyle("first")}>
          <div className={aboveCellContainer("first")}>
            <div className={classes.aboveBox}>
              <div>
                <img src={withPrefix('images/infographicDesktop/Group348_picture.png')} alt="" />
              </div>
              <div className={classes.inWord}>
                <p>in</p>
              </div>
              <div>
                <img src={withPrefix('images/infographicDesktop/Group349_picture.png')} alt="" />
              </div>
            </div>
            <div className={classes.textContentContainer}>
              <p className={classes.textContent}>have been affected by partial or full school closures</p>
            </div>
          </div>
          <div className={aboveCellContainer("second")}>
            <img src={withPrefix('images/infographicDesktop/Group324.png')} alt=""/>
            <div>
              <p className={classes.textContent}>of the world’s school and university students had used some form of digital tools to continue learning
              from home during the pandemic
              <br />
              (UNESCO, 2020; Credit Suisse, 2020).</p>
            </div>
          </div>
        </div>
        <div className={rowStyle("second")}>
          <div className={belowCellContainer("third")}>
            <div className={classes.belowRowContainer}>
              <p className={classes.text}>The uptake of EdTech also features in the business sector and at the government level</p>
              <div className={classes.graphCont}>
                <img className={classes.leftBlock} src={withPrefix('images/infographicDesktop/Group325.png')} alt="" />
                <div className={classes.rightBlock}>
                  <div>Businesses looking to digitise their organisation</div>
                  <div>Governments looking to reskill and upskill people for the increased demand in digital jobs</div>
                </div>
              </div>
            </div>
          </div>
          <div className={belowCellContainer("fourth")}>
            <div className={classes.belowRowContainer}>
              <p className={classes.aboveTextContent}>However, the sudden and rapid increase in EdTech has also evinced the
                issue of social inequality, with digital divides over access to technology becoming more apparent.</p>
              <div className={classes.graphContainer}>

                <div className={classes.lastGraphContainer}>
                  <div className={classes.columnGraphContainer}>
                    <div className={classes.graphDescriptionContainer}>
                      <p className={classes.description}>In the UK, pupils provided with devices to take home</p>
                    </div>
                    <img src={withPrefix('images/infographicDesktop/Group347.png')} alt="" />
                    <p className={classes.columnGraphContainerText}>primary state schools</p>
                  </div>
                  <div className={classes.columnGraphContainer}>
                    <img src={withPrefix('images/infographicDesktop/Group346.png')} alt="" />
                    <p className={classes.columnGraphContainerText}>private primary schools</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.lastContent}>
          <p>
            Currently, the US hosts half of the world’s EdTech companies, though China is catching up fast, accounting
            for 8 of the eighteen private EdTech companies with a valuation of over USD1bn. While there are fewer
            ‘unicorns’ in Europe, the market there is growing too, with the UK, home to the Learning Technology Group,
            Europe’s only EdTech unicorn, leading the way.
            <br />
            (Holon IQ, 2020; Metaari, 2020).
          </p>
        </div>
      </div>
    </>
  )
};

export default InfographicContentType6;
