import React from 'react';
import {withPrefix} from 'gatsby';
import classes from './InfographicCardSection.module.scss';
import InfographicContentType1 from "./components/InfographicContentType1/InfographicContentType1";
import InfographicContentType2 from "./components/InfographicContentType2/InfographicContentType2";
import InfographicContentType3 from "./components/InfographicContentType3/InfographicContentType3";
import InfographicContentType4 from "./components/InfographicContentType4/InfographicContentType4";
import InfographicContentType5 from "./components/InfographicContentType5/InfographicContentType5";
import InfographicContentType6 from "./components/InfographicContentType6/InfographicContentType6";
import InfographicContentType7 from "./components/InfographicContentType7/InfographicContentType7";
import InfographicContentType8 from "./components/InfographicContentType8/InfographicContentType8";
import PropTypes from 'prop-types';

const InfographicCardSection = ({headerComponent, bodyComponent, frontImgPath}) => {

	const Components = {
		'ContentType1': InfographicContentType1,
		'ContentType2': InfographicContentType2,
		'ContentType3': InfographicContentType3,
		'ContentType4': InfographicContentType4,
		'ContentType5': InfographicContentType5,
		'ContentType6': InfographicContentType6,
		'ContentType7': InfographicContentType7,
		'ContentType8': InfographicContentType8,
	};

	const HeaderComponent = Components[headerComponent];
	const BodyComponent = Components[bodyComponent];

	const rowStyle = (rowNum) => {
		return [
			classes.row,
			classes[`row${rowNum}`]
		].join(' ');
	}

	return (
		<>
			<div className={classes.wrapper}>
				<div className={classes.background}/>
				<div className={classes.content}>
					<div className={rowStyle("First")}>
						<img src={withPrefix(frontImgPath)} alt="" className={classes.mainPicture} />
						<HeaderComponent headerComponent={headerComponent}/>
					</div>
					<div className={rowStyle("Second")}>
						<BodyComponent/>
					</div>
				</div>
			</div>
		</>
	)
};

InfographicCardSection.propTypes = {
	headerComponent: PropTypes.string.isRequired,
	bodyComponent: PropTypes.string.isRequired,
	frontImgPath: PropTypes.string.isRequired,
};

export default InfographicCardSection;
