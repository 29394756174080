import React from 'react';
import classes from './InfographicContentType1.module.scss';
import {withPrefix} from "gatsby";


const InfographicContentType1 = () => {

  return (
    <>
    <div className={classes.globalContainer}>
      <p>As global dependence on digital technologies increases - and as lockdowns force individuals, businesses
        and governments to move their operations online - the risks of cyberthreats will continue to rise.</p>
      <p>
        It is estimated that the overall global cost of cybercrime might reach
      </p>
      {/*<div dangerouslySetInnerHTML={{__html: firstContent}} />*/}
      <div className={classes.pictContainer}>
        <img src={withPrefix('images/infographicDesktop/Group_362.png')} alt="" />
      </div>
      <div className={classes.textContainer}>
        <p>
          while the average cost of breaches disclosed by public firms hit $116m in 2019, a figure likely to
          have been exceeded in 2020
          <br />
          (Cybersecurity Ventures, 2020; Audit Analytics, 2020).
        </p>
      </div>
    </div>
    </>
  )
};

export default InfographicContentType1;
