import React from 'react';
import classes from './StatisticBox.module.scss';
import PropTypes from 'prop-types';
import {withPrefix} from 'gatsby';

const StatisticBoxLight = ({light: {title, percentImgPath, text}}) => {
  const classList = [
    classes.box,
    classes.light
  ].join(' ');

  return (
    <>
      <div className={classList}>
        <div dangerouslySetInnerHTML={{__html: title}} className={classes.bigText}/>
        <img src={withPrefix(percentImgPath)} alt="" />
        <div dangerouslySetInnerHTML={{__html: text}} className={classes.smallText}/>
      </div>
    </>
  )
};

StatisticBoxLight.propTypes = {
  light: PropTypes.shape({
    title: PropTypes.string.isRequired,
    percentImgPath: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired
};

export default StatisticBoxLight;
